import VerticalLine_VerticalArrowLineTopComponent from '@wix/thunderbolt-elements/src/components/VerticalLine/viewer/skinComps/BaseVerticalLine/VerticalArrowLineTop.skin';


const VerticalLine_VerticalArrowLineTop = {
  component: VerticalLine_VerticalArrowLineTopComponent
};


export const components = {
  ['VerticalLine_VerticalArrowLineTop']: VerticalLine_VerticalArrowLineTop
};


// temporary export
export const version = "1.0.0"
